const PAGE_TYPES = ["page", "subpage", "external"];
import Link from "next/link";
import { Link as ScrollLink } from "react-scroll";

import { useRouter } from "next/router";
import { FaExternalLinkAlt } from "react-icons/fa";

export default function PageEntry(props) {
    if (!PAGE_TYPES.includes(props.type)) throw new Error(`Invalid page type: Can be one of ${PAGE_TYPES.join(", ")}`);
    const router = useRouter();
    const currentPage = router.pathname;

    const classes = {
        iconPageActive: "text-cyan-300 transition ease-in-out duration-200",
        iconPageInactive: "text-gray-300 hover:text-gray-400 transition ease-in-out duration-200",
        textPageActive: "text-white hover:text-gray-300 transition ease-in-out duration-200",
    };

    return (
        <>
            {
                props.type === "page" && (
                    <>
                        <Link href={props.href} className={`flex flex-row items-center gap-x-2 ${(currentPage.startsWith(props.href) || currentPage.startsWith(props.href)) ? `text-white ${classes.textPageActive}` : "hover:text-gray-400 transition ease-in-out duration-200"} text-2xl md:text-lg`}>
                            <props.icon className={`w-5 ${(currentPage.startsWith(props.href) || currentPage.startsWith(props.href)) ? classes.iconPageActive : classes.iconPageInactive}`} /> {props.children} 
                        </Link>
                    </> 
                )
            }
            {
                props.type === "external" && (
                    <>
                        <Link href={props.href} target="_blank" rel="noreferrer" className={`flex flex-row items-center gap-x-2 ${classes.iconPageInactive} text-2xl md:text-lg`}>
                            <props.icon className={`w-5`} /> {props.children}
                            <FaExternalLinkAlt className="w-2.5" />
                        </Link>
                    </>
                )
            }
            {
                props.type === "subpage" && (
                    <>
                        {
                            (router.pathname === props.rootPage) ? (
                                <ScrollLink to={props.toSection} smooth={true} onClick={props.onSetActive}>
                                    <button className={`flex flex-row items-center gap-x-2 ${props.isActive ? classes.textPageActive : "hover:text-gray-400 transition ease-in-out duration-200"} text-2xl md:text-lg`}>
                                        <props.icon className={`w-5 ${props.isActive ? classes.iconPageActive : classes.iconPageInactive}`} /> {props.children} 
                                    </button>
                                </ScrollLink>
                            ) : (
                                <Link href={props.href} className={`flex flex-row items-center gap-x-2 hover:text-gray-400 transition ease-in-out duration-200 text-2xl md:text-lg`}>
                                    <props.icon className={`w-5 ${classes.iconPageInactive}`} /> {props.children}
                                </Link>
                            )
                        }
                    </>
                )
            }
        </>
    );
};