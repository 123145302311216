function cleanSubstring(s, searchTerm) {
    var str = s;
    var n = str.search(searchTerm);
    while (str.search(searchTerm) > -1) {
        n = str.search(searchTerm);
        str = str.substring(0, n) + str.substring(n + searchTerm.length, str.length);
    }
    return str;
};

export { cleanSubstring };