import Link from "next/link";
import { useRouter } from "next/router";
import Scroll from "react-scroll";

import { useState, useEffect, useContext } from "react";
import PageEntry from "/componentry/Root/NavPartials/Pages/PageEntry";
import { FaBriefcase, FaHome, FaImages, FaQuestionCircle, FaUser, FaUserCircle, FaYoutube } from "react-icons/fa";
import { NavContext } from "/componentry/Root/Nav";

export default function NavSocials(props) {
    const { navContext, setNavContext } = useContext(NavContext);

    const router = useRouter();
    
    const [homeSection, setHomeSection] = useState({ path: null, name: "home" });

    const classes = {
        iconPageActive: "text-cyan-300 transition ease-in-out duration-200",
        iconPageInactive: "text-gray-300 hover:text-gray-400 transition ease-in-out duration-200",
        textPageActive: "text-white hover:text-gray-300 transition ease-in-out duration-200",
    }; 

    const homeOnSetActive = () => {
        setHomeSection({ path: "/#/", name: "home" });
        setNavContext({ ...navContext, isMobileNavOpen: false });
        window.location.href = "/#/";
    };

    const aboutOnSetActive = () => {
        setHomeSection({ path: "/#/about", name: "about" });
        setNavContext({ ...navContext, isMobileNavOpen: false });
        router.replace("/#/about", undefined, { shallow: true });
    };

    const currentPage = router.pathname;

    useEffect(() => {
        if (currentPage === "/") {
            switch(router.asPath) {
                case "/#/":
                case "/":
                    setHomeSection({ path: "/#/", name: "home" });
                    if (router.asPath === "/#/") {
                        setTimeout(() => {
                            Scroll.scroller.scrollTo("home", {
                                smooth: true,
                                duration: 1000,
                                delay: 500
                            });
                        }, 500)
                        window.location.href = "/";
                    };
                    break;
                
                case "/#/about":
                case "/#about":
                    setHomeSection({ path: "/#/about", name: "about" });
                    if (router.asPath === "/#about") {
                        setTimeout(() => {
                            Scroll.scroller.scrollTo("about", {
                                smooth: true,
                                duration: 1000,
                                delay: 500
                            });
                        }, 500)
                        window.location.href = "/#/about";
                    } else {
                        Scroll.scroller.scrollTo("about", {
                            smooth: true,
                            duration: 1000
                        });
                    };
                    break;
            };
        };
    }, [router.asPath]);

    return (
        <div className={`bg-transparent h-full w-full`}>
            <div className="flex flex-row md:block md:sticky md:top-0 w-full">
                <h1 className="hidden md:inline-block px-6 py-4 text-white">Menu</h1>
                <div className="flex flex-col md:px-8 mr-0 lg:mr-2 space-y-4 text-lg text-gray-300">
                    <PageEntry type="subpage" href="/" rootPage="/" toSection="home" icon={FaHome} onSetActive={homeOnSetActive} isActive={(currentPage === "/" && homeSection.name === "home")}>Home</PageEntry>
                    <PageEntry type="subpage" href="/#/about" rootPage="/" toSection="about" icon={FaUser} onSetActive={aboutOnSetActive} isActive={(currentPage === "/" && homeSection.name === "about")}>About</PageEntry>
                    <PageEntry type="page" href="/v" icon={FaYoutube}>Videos</PageEntry>
                    <PageEntry type="page" href="/thumbnails" icon={FaImages}>Thumbnails</PageEntry>
                    <PageEntry type="page" href="/clients" icon={FaUserCircle}>Clients</PageEntry>
                    <PageEntry type="page" href="/faq" icon={FaQuestionCircle}>FAQ</PageEntry>
                    <PageEntry type="page" href="/jobs" icon={FaBriefcase}>Jobs</PageEntry>
                    <Link href="mailto:business@kooleyy.com"  target="_blank" rel="noreferrer" className="hidden xl:hidden items-center justify-center flex-row font-BreezeHeader gap-x-2 text-sm px-6 py-2 bg-cyan-300 hover:bg-cyan-400 active:shadow-lg transition duration-150 ease-in-out text-sp text-black rounded-full text-center">
                        Contact
                    </Link>
                </div>
            </div>
        </div>
    );
};