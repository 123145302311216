const dev = process.env.NODE_ENV !== 'production';

const playlistIds = {
    soloVideos: "PLyq12hyWVkFY7DAQiGwR-PTzZSAn5aqMq",
    teamVideos: "PLyq12hyWVkFaEYPlpzStPbYTLqOVtnOZV",
    bestVideos: "PLyq12hyWVkFbpQFAGs-DIxBOugVgP1W_Y"
};

const defaultResponsiveCarousel = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 5
    }
};

const trdLocale = {
	"justNow": "Just now",
	"secondsAgo": "{{time}} seconds ago",
	"aMinuteAgo": "1 minute ago",
	"minutesAgo": "{{time}} minutes ago",
	"anHourAgo": "1 hour ago",
	"hoursAgo": "{{time}} hours ago",
	"aDayAgo": "1 day ago",
	"daysAgo": "{{time}} days ago",
	"aWeekAgo": "1 week ago",
	"weeksAgo": "{{time}} weeks ago",
	"aMonthAgo": "1 month ago",
	"monthsAgo": "{{time}} months ago",
	"aYearAgo": "1 year ago",
	"yearsAgo": "{{time}} years ago",
	"overAYearAgo": "2 years ago",
	"secondsFromNow": "{{time}} seconds from now",
	"aMinuteFromNow": "1 minute from now",
	"minutesFromNow": "{{time}} minutes from now",
	"anHourFromNow": "1 hour from now",
	"hoursFromNow": "{{time}} hours from now",
	"aDayFromNow": "Tomorrow",
	"daysFromNow": "{{time}} days from now",
	"aWeekFromNow": "1 week from now",
	"weeksFromNow": "{{time}} weeks from now",
	"aMonthFromNow": "1 month from now",
	"monthsFromNow": "{{time}} months from now",
	"aYearFromNow": "1 year from now",
	"yearsFromNow": "{{time}} years from now",
	"overAYearFromNow": "Over a year from now"
};

// modify first value to switch between local and remote server
const local = false;

export const localBool = dev ? local : false;

const server = localBool ? "http://127.0.0.1:5043" : "https://kooleyyapi.hydrabank.world";
const bookshelf = "https://bookshelf.kooleyy.com";
//const bookshelf = "http://localhost:1337";
const commitHash = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA?.slice(0, 7) || "localhost";
const commitBranch = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF || "localhost";
const vercelEnv = process.env.NEXT_PUBLIC_VERCEL_ENV || "localhost";

const blurImageURL = {
    client: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEABAMAAACuXLVVAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAADUExURWl3iRRmGcAAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAA2SURBVHja7cEBAQAAAIIg/69uSEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwLkagQAAAUwQO5QAAAAASUVORK5CYII=",
    video: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAYAAAADYAQMAAADYo6bSAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAADUExURWd3iGn/BFwAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAgSURBVGje7cEBAQAAAIIg/6+uIUAAAAAAAAAAAAAAXA0pWAABoJ6l1AAAAABJRU5ErkJggg=="
};

const vClasses = {
    enabled: "inline-block px-4 py-2 text-gray-900 font-bold bg-cyan-300 rounded-lg active",
    disabled: "inline-block px-4 py-2 rounded-lg hover:text-gray-900 font-bold hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white"
};

export { server, bookshelf, playlistIds, defaultResponsiveCarousel, dev, trdLocale, commitHash, commitBranch, vercelEnv, blurImageURL, vClasses };