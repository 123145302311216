import Link from "next/link";
import Image from 'next/image';
export default function NavSearch() {
    return (
        <div className="flex flex-row gap-x-3 md:gap-x-4 justify-center content-center" id="PrimaryNav-Top-NameInfo">
            <Link href="/" className="w-12">
                <Image
                    width="128"
                    height="128"
                    src="/kooleyy.png"
                    alt="Kooleyy's Avatar"
                    className="rounded-full"
                    priority={true}
                />
            </Link>
            <Link href="/" className="flex flex-col justify-center content-center">
                <h1 className="text-lg">Kooleyy</h1>
                <h2 className="hidden sm:block text-sm text-gray-300 whitespace-pre">Video Editor<b className="font-bold hidden md:inline-block"> · Content Manager</b></h2>
            </Link>
        </div>
    );
};