import { useRouter } from "next/router";
import { useState, useEffect, useContext } from "react";
import { PagewideContext } from "/componentry/Root/Shared/PagewideContext.jsx";
import { NavContext } from "/componentry/Root/Nav";
import { IoClose } from "react-icons/io5";
import {encode} from 'html-entities';
import { FaSearch, FaSpinner, FaTruckLoading } from "react-icons/fa";
import { cleanSubstring } from "/lib/helpers";

export default function NavSearch(props) {
    const router = useRouter();
    const [search, setSearch] = useState(null);
    const [isEmpty, setIsEmpty] = useState(true);

    const { navContext, setNavContext } = useContext(NavContext);
    const { pageContext, setPageContext } = useContext(PagewideContext);

    useEffect(() => {
        if (router.isReady) {
            if (router.pathname.startsWith("/v/s") && (router.query.q !== "" && router.query.q !== undefined && router.query.q !== null)) setSearch(router.query.q);
        };
    }, [router.isReady]);

    function handleSubmit(evt) {
        let cleanSearch = cleanSubstring(search || "", "&").trim();
        evt.preventDefault();
        if (pageContext?.carry?.submitSearchDisabled) return;

        let filters = pageContext?.carry?.filters?.changed ? pageContext?.carry?.filters?.filters : null;
        let filterStr = "";
        if (filters !== null) {
            let filterObj = Object.keys(filters);
            filterObj.forEach((filter, i) => {
                if (filters[filter] === pageContext?.carry?.filters?.defaultFilters[filter]) return;
                if (i === 0) filterStr += `${filter}=${filters[filter]}`;
                else
                filterStr += `&${filter}=${filters[filter]}`;
            });
        };

        if (cleanSearch === null || cleanSearch === "") {
            router.push(`/v`).then(() => setNavContext({ ...navContext, isMobileSearchOpen: true }));
            setPageContext({ ...pageContext, search: null, carry: { ...pageContext.carry, submitSearchDisabled: true } });
        } else if (cleanSearch !== null && cleanSearch !== "" && cleanSearch !== router.query.q) {
            setPageContext({ ...pageContext, search: cleanSearch || null, carry: { ...pageContext.carry, submitSearchDisabled: true } });
            setNavContext({ ...navContext, isMobileSearchOpen: false });
            router.push(`/v/s?q=${cleanSearch}${filterStr !== "" ? `&${filterStr}` : ""}`).then(() => setNavContext({ ...navContext, isMobileSearchOpen: true }));
        };
    };

    function handleChange(evt) {
        setSearch(cleanSubstring(evt.target.value, "&") || null);
        if (document.getElementById("q").value.trim() === "") setIsEmpty(true);
        else setIsEmpty(false);
    };

    function handleClick() {
        if (document.getElementById("q").value.trim() === "") {
            if (!isEmpty) setIsEmpty(true);
        }
        else {
            if (isEmpty) setIsEmpty(false);
        }
    };

    return (
        <div className="w-full sm:w-[70%] md:w-[60%] lg:ml-2 px-4">
            <form action="/v/s" method="GET" onSubmit={handleSubmit}>
                <label htmlFor="q" className="mb-2 text-sm font-medium sr-only text-gray-300">Search</label>
                <div className="block relative">
                    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <svg aria-hidden="true" className="hidden w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                        { 
                            pageContext?.carry?.submitSearchDisabled ? 
                                <FaSpinner className="w-5 text-gray-400 animate-spin" />
                            :
                                <FaSearch className="w-5 text-gray-400" />
                        }
                    </div>
                    <div className={`hidden md:flex absolute inset-y-0 right-0 items-center justify-center my-4 mr-6 rounded-r-full pointer-events-none`}>
                        <IoClose className={`${isEmpty ? "md:hidden md:w-0 md:h-0" : "md:text-white"} md:bg-gray-800 text-slate-900 bg-slate-900 -mr-2`} />
                    </div>
                    <input type="search" name="q" id="q" className={`${props.mobile || isEmpty ? "no-search-x" : ""} bg-slate-900 md:bg-gray-800 bg-transparent block py-2 pl-10 w-full text-sm rounded-full placeholder-gray-400 border-gray-500 text-white focus:ring-transparent focus:border-gray-400 md:focus:ring-blue-500 md:focus:border-blue-500 font-BreezeAltHeader font-medium text-center md:text-left`} formNoValidate noValidate title="" placeholder="Search all videos" defaultValue={(search !== null && router.pathname.startsWith("/v/s")) ? search : undefined} 
                        onChange={handleChange} 
                        onClick={handleClick}
                    />
                </div>
            </form>
        </div>
    );
};