import { useState, useEffect } from "react";
import { NextSeo } from "next-seo";
import { useRouter } from "next/router";
import "/styles/globals.css";
import "/styles/swiper.css";

import { PagewideContext } from "/componentry/Root/Shared/PagewideContext.jsx";
import { Analytics } from '@vercel/analytics/react';

import Scrollbar from "/componentry/Root/Scrollbar.jsx";
import Nav from "/componentry/Root/Nav.jsx";

// NProgress
import Router from 'next/router';
import NProgress from 'nprogress';
import '/styles/nprogress.css'; // Custom styles for NProgress

Router.events.on('routeChangeStart', () => NProgress.start()); Router.events.on('routeChangeComplete', () => NProgress.done()); Router.events.on('routeChangeError', () => NProgress.done());  
// End NProgress

const pages = [
    { name: "Home", href: "/" },
    { name: "Videos", href: "/v" },
    { name: "Clients", href: "/clients" },
    { name: "FAQ", href: "/faq" },
    { name: "Jobs", href: "/jobs" },
    { name: "Thumbnails", href: "/thumbnails" },
    { name: "Search results", href: "/v/s" },
]

export default function Wireframe({ Component, pageProps }) {
    const [pageContext, setPageContext] = useState({ currentRoute: null, nav: { isMobileNavOpen: false }, videoType: "videos", videoTypeLoading: false, carry: { clients: [], thumbnailClients: [] } });
    const [currentRoute, setCurrentRoute] = useState(null);
    const router = useRouter();

    useEffect(() => {
        if (router.pathname !== currentRoute) {
            if (!router.pathname.startsWith("/v/s")) {
                setPageContext({ ...pageContext, currentRoute: router.pathname, head: { title: null }, nav: { isMobileNavOpen: false }, videoType: "videos", videoTypeLoading: false });
            } else {
                setPageContext({ ...pageContext, currentRoute: router.pathname, nav: { isMobileNavOpen: false }, videoType: "videos", videoTypeLoading: false });
            };
        };
    }, [router.pathname, currentRoute])

    return ( 
        <>
            <Analytics />
            <PagewideContext.Provider value={{pageContext, setPageContext}}>
                <div className={`${(router.query.theme !== "old") ? "" : "bg-slate-800"} h-screen dark`}>
                    <NextSeo
                        title={
                            pageContext.head?.title ? pageContext.head?.title : pages.find(page => router.pathname == (page.href)) ? `${pages.find(page => router.pathname == (page.href)).name} - Kooleyy` : "Kooleyy"
                        }
                        description="Video editor and content manager."
                        additionalMetaTags={
                            [
                                {
                                    name: "theme-color",
                                    content: "#67e8f9"
                                }
                            ]
                        }
                    />
                    <Scrollbar />
                    <Nav component={<Component {...pageProps} />} />
                </div>
            </PagewideContext.Provider>
        </>
    );
};