import { server } from "/lib/publicEnv";
import { pickRandom } from "/lib/mathsExtension";
async function fetchClients(p, noWrite) {
    const { context, bigClients, thumbnails } = p || {};
    const { state, setState, loading } = context || {};
    if (!noWrite) {
        //if (!loading) return;
        const cohort = thumbnails ? state?.carry?.thumbnailClients : state?.carry?.clients;
        if (cohort?.length > 0) {
            const bigClient = pickRandom(cohort.sort((a, b) => b.subscriberCount - a.subscriberCount).filter(c => {
                return c.channelSocial.subscribers >= 250000;
            }), 16);

            return bigClients ? bigClient : cohort;
        } else {
            let data = await fetch(`${server}/api/getClients${thumbnails ? "?thumbnails=true" : ""}`).then(f => {
                return f.json();
            }).catch(e => {
                console.error(e);
                return {
                    error: true
                };
            });
        
            if (!data.error) {
                const bigClient = pickRandom(data?.data?.sort((a, b) => b.subscriberCount - a.subscriberCount).filter(c => {
                    return c.channelSocial.subscribers >= 250000;
                }), 16);


                if (thumbnails) {
                    console.log("Thumbnail clients not carried, refreshing...");
                    if (!noWrite) setState({ ...state, carry: { ...state.carry, thumbnailClients: data?.data } });
                } else {
                    console.log("Clients not carried, refreshing..");
                    if (!noWrite) setState({ ...state, carry: { ...state.carry, clients: data?.data } });
                }
                return bigClients ? bigClient : data?.data;
            };
        };
    } else {
        console.log("Client: No-write request sent");
        let data = await fetch(`${server}/api/getClients${thumbnails ? "?thumbnails=true" : ""}`).then(f => {
            return f.json();
        }).catch(e => {
            console.error(e);
            return {
                error: true
            };
        });
    
        if (!data.error) {
            const bigClient = pickRandom(data?.data?.sort((a, b) => b.subscriberCount - a.subscriberCount).filter(c => {
                return c.channelSocial.subscribers >= 250000;
            }), 16);
            
            return bigClients ? bigClient : data?.data;
        };
    };
};

export default fetchClients;