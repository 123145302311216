"use client";
import NavSocials from "/componentry/Root/NavPartials/NavSocials";
import NavPages from "/componentry/Root/NavPartials/NavPages";
import NavSearch from "/componentry/Root/NavPartials/NavSearch";
import NavHead from "/componentry/Root/NavPartials/NavHead";

import { FaBars, FaSearch } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { AiFillMessage } from "react-icons/ai";

import manifest from "/package.json";
const copium = manifest.copium;

import { uniformClasses } from "/componentry/Root/NavPartials/NavSocials";

import { commitHash, commitBranch, vercelEnv, localBool } from "/lib/publicEnv";

import { useRouter } from "next/router";
import Link from "next/link";

import { useContext, useEffect, useState, createContext } from "react";
import { PagewideContext } from "/componentry/Root/Shared/PagewideContext.jsx";

import { Element as ScrollElement } from "react-scroll";

export const NavContext = createContext({
    navContext: { 
        isMobileNavOpen: false,
        isMobileSearchOpen: false,
        isDevInfoOpen: false,
    },
    setNavContext: undefined,
});

const footerBlacklist = [
    "/",
    "/faq"
];

import BottomBar from "/componentry/Root/Shared/BottomBar";

export default function Nav(props) {
    const { pageContext, setPageContext } = useContext(PagewideContext);
    const [ navContext, setNavContext ] = useState({ isMobileNavOpen: false, isMobileSearchOpen: false, search: null });
    const router = useRouter();

    useEffect(() => {
        if (router.pathname !== pageContext.currentRoute) {
            setNavContext({ ...navContext, isMobileNavOpen: false, search: null });
        };

        if (navContext.isSearchOpen === true && navContext.isMobileNavOpen === true) {
            setNavContext({ ...navContext, isMobileNavOpen: false, isMobileSearchOpen: true });
        };
    }, [router.isReady, pageContext, router.pathname]);
    
    return (
        <NavContext.Provider value={{ navContext, setNavContext }}>
            <div className={`text-white font-BreezeHeader font-bold`}>
                <ScrollElement name="home" />
                <div className={`bg-amber-900 w-full py-4 px-4 hidden ${(router.query.theme === "old") ? "sm:flex" : ""}`}>
                    <h1 className="font-BreezeButton font-bold text-md">
                        You are viewing kooleyy.com with the <b>old theme (circa copium 3)</b>. This theme is very outdated and unstable, <b>expect many bugs and visual glitches</b>.
                        <br />
                        The navigation module does not support secondary themes at this time and will reset the <code>theme</code> query string when navigating to new pages with it. Use an extension or script to retain <code>?theme=old</code> in your requests.
                    </h1>
                </div>
                <div className={`bg-blue-800 w-full py-1 px-4 ${(localBool === true) ? "flex" : "hidden"}`}>
                    <h1 className="font-BreezeButton font-bold text-md">
                        NOTE: This build of kooleyy.com is running with a <b>local</b> API server. You can disable this in <code>lib/publicEnv.js</code>.
                    </h1>
                </div>
                <div className={`bg-red-800 w-full py-1 px-4 ${((commitBranch === "master" || commitBranch === "localhost") && vercelEnv !== "production") ? "flex" : "hidden"}`}>
                    <h1 className="font-BreezeButton font-bold text-md">
                        Running development build <code>copium-{copium}</code> (SHA hash <code>{commitHash}</code>{localBool === true ? " with a local API server" : ""})
                    </h1>
                </div>
                <div className={`md:bg-slate-900 drop-shadow-xl md:drop-shadow-none sm:px-6 py-6 md:pb-4 relative hidden md:flex flex-col gap-y-4 md:flex-row items-center justify-between`} id="PrimaryNav-Top">
                        <NavHead />
                        <NavSearch />
                        <Link href="mailto:business@kooleyy.com"  target="_blank" rel="noreferrer" className="hidden xl:flex flex-row font-BreezeHeader gap-x-2 text-sm px-6 py-2 bg-cyan-300 hover:bg-cyan-400 active:shadow-lg transition duration-150 ease-in-out text-sp text-black rounded-full text-center">
                            Contact
                        </Link>
                        <div className="hidden md:flex absolute inset-y-0 right-0 items-center pr-10 sm:pr-6 sm:static sm:inset-auto sm:ml-6 gap-x-6 text-xl lg:text-2xl">
                            <Link href="mailto:business@kooleyy.com" target="_blank" className="xl:hidden" rel="noreferrer">
                                <AiFillMessage className={uniformClasses.replace("hover:text-gray-300", "text-cyan-400 hover:text-cyan-500")} />
                            </Link>
                            <NavSocials />
                        </div> 
                </div>
                <div className={`sticky top-0 flex md:hidden items-center flex-col bg-gray-900 z-50 ${navContext.isMobileSearchOpen ? "py-[1.8125rem]" : "py-6"}`}>
                    <div className="flex flex-row px-12 justify-between sm:justify-evenly items-center w-full">
                        <button aria-label="Navigation Menu" onClick={(() => setNavContext({ ...navContext, isMobileNavOpen: !navContext?.isMobileNavOpen }))} className={`${navContext.isMobileSearchOpen ? "hidden" : ""} hover:text-gray-300`}>
                            {
                                navContext.isMobileNavOpen ? <IoClose className="w-5 font-bold text-white text-3xl" /> : <FaBars className="w-5 font-bold text-white text-3xl" />
                            }
                        </button>
                        { navContext.isMobileSearchOpen ? <NavSearch mobile /> : <NavHead /> }
                        <button  aria-label="Search for videos" onClick={(() => setNavContext({ ...navContext, isMobileSearchOpen: !navContext?.isMobileSearchOpen }))} className="hover:text-gray-300">
                            {
                                navContext.isMobileSearchOpen ? <IoClose className="w-5 font-bold text-white text-3xl" /> : <FaSearch className="w-5 font-bold text-white text-3xl" />
                            }
                        </button>
                    </div>
                    <div className={`fixed top-0 left-0 h-screen flex ${navContext.isMobileNavOpen ? "flex md:hidden" : "hidden"} z-[1000]`}>
                        <div className={`flex flex-col gap-y-6 h-full pl-6 pr-24 bg-gray-900 py-6 ${navContext.isMobileNavOpen ? "flex md:hidden" : "hidden"} z-40`}>
                            <button onClick={(() => setNavContext({ ...navContext, isMobileNavOpen: !navContext?.isMobileNavOpen }))} className="hover:text-gray-300">
                                <IoClose className="font-bold text-white text-3xl" />
                            </button>
                            <div className="pl-6">
                                <NavPages />
                            </div>
                        </div>
                        <div className="bg-black w-screen opacity-75 z-10" onClick={(() => setNavContext({ ...navContext, isMobileNavOpen: !navContext?.isMobileNavOpen }))} />
                    </div>
                </div>
                <div className={`flex flex-row h-full ${pageContext.hideScroll ? "no-scrollbar" : ""}`}>
                    <div className="hidden md:flex flex-col">
                        <NavPages />
                        <button 
                            onClick={(() => setNavContext(
                                    {
                                        ...navContext,
                                        isDevInfoOpen: !navContext?.isDevInfoOpen
                                    }
                                ))
                            }
                            className="w-fit h-fit"
                        >
                            {
                                (navContext.isDevInfoOpen) && (
                                    <div className="flex flex-col gap-y-2 bg-slate-900 text-gray-400 rounded-xl text-sm text-left mb-4">
                                        kooleyy.com {vercelEnv === "production" ? "prod" : "dev"} build <br />
                                        (c) 2023 continuity
                                    </div>
                                )
                            }
                            <div className={`text-sm text-slate-900 hover:text-gray-400 w-fit`}>build c.{copium} ({manifest.version})</div>
                        </button>
                    </div>
                    <div className={`flex flex-col w-full ${pageContext.hideScroll ? "no-scrollbar" : ""}`}>
                        <div className={`min-h-[90%] bg-slate-800 md:rounded-tl-3xl w-full h-full ${pageContext.hideScroll ? "no-scrollbar" : ""}`}>
                            <div className={`${router.pathname === "/jobs" ? "flex flex-row items-center justify-center" : "hidden"} text-black bg-yellow-400 w-full py-1 px-4`}>
                                <h1 className="font-BreezeButton font-bold text-md">
                                    This build of Jobs is in beta. Please help us improve this experience by providing feedback with suggestions or bugs!
                                </h1>
                                <Link href="https://docs.google.com/forms/d/1lPxuCGXyYPtUZEIXDYiNG5G7YFIVZ4H_n4LSCLGGA88"  target="_blank" rel="noreferrer noopener" className="bg-cyan-300 hover:bg-cyan-400 ml-2 px-2 py-1 text-sm text-center items-center font-BreezeHeader rounded-full w-fit">
                                    Feedback form
                                </Link>
                            </div>
                            {props.component}
                        </div>
                        <div className={`bg-gray-700 w-full ${(footerBlacklist.includes(router.pathname) || pageContext.hideFooter) ? "hidden" : ""}`}>
                            <BottomBar />
                        </div>
                    </div>
                </div>
            </div>
        </NavContext.Provider>
    );
};