// Code from this answer: https://stackoverflow.com/questions/19269545/how-to-get-a-number-of-random-elements-from-an-array
export function pickRandom(arr, count) {
    let _arr = [...arr];
    return[...Array(count)].map( ()=> _arr.splice(Math.floor(Math.random() * _arr.length), 1)[0] ); 
};


// Code from this answer: https://stackoverflow.com/questions/6312993/javascript-seconds-to-time-string-with-format-hhmmss
export function formatTime(seconds, hideSeconds = false) {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.round(seconds % 60);

    if (hideSeconds) {
        return [
            h,
            m > 9 ? m : (h ? '0' + m : m || '0')
        ].filter(Boolean).join(':');
    }

    return [
        h,
        m > 9 ? m : (h ? '0' + m : m || '0'),
        s > 9 ? s : '0' + s
    ].filter(Boolean).join(':');
};